import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setup-sequel-pro"
    }}>{`Setup Sequel Pro`}</h1>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Make sure the database has been started using`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`docker-compose up
`}</code></pre>
        <p parentName="li">{`For more information see `}<a parentName="p" {...{
            "href": "./RunningLocally.md"
          }}>{`Running Locally`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Download `}<a parentName="p" {...{
            "href": "https://www.sequelpro.com/"
          }}>{`Sequel Pro`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install Sequel Pro`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open Sequel Pro`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click Standard`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Name: localhost`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Username: root`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Port: 3307 `}{`(`}{`The MySQL standard is to use 3306, i've used 3307 to avoid clashes`}{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Test Connection`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Save as Favorites`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Connect`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the glue database on the top left dropdown`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Have a look around`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      